import React from 'react'
import TimeCountDown from '../countdown';
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import hero1 from '../../images/slider/slide-1.jpg'
import hero2 from '../../images/slider/slide-2.jpg'
import hero3 from '../../images/slider/slide-3.jpg'

const HeroCountdown = (props) => {
    return (
        <section className="wpo-hero-slider wpo-hero-style-3">
            <div className="wedding-announcement">
                <div className="couple-text">
                    <h2 className="wpo-title">Ania <span>&</span> Adrian</h2>
                    <p className="wpo-description">Bierzemy ślub!</p>
                    <p >26 MAJA 2024 o godzinie 14:00 </p>
                    <div className="wpo-wedding-date">
                        <div className="clock-grids">
                            <TimeCountDown/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
            </div>

        </section>
    )
}

export default HeroCountdown;