import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Scrollbar from '../../components/scrollbar'
import StorySection from '../../components/StorySection/StorySection';
import EventSection from '../../components/EventSection/EventSection';
import HeroCountdown from "../../components/hero3/HeroCountdown";
import Info from "../../components/hero4/Info";
import VideoSection from "../../components/VideoSection";
import Footer from "../../components/footer/Footer";



const HomePage = ({hotel}) => {

    return(
        <Fragment>
            <Navbar/>
            <HeroCountdown/>
            <StorySection/>
            <VideoSection/>
            <EventSection hotel={hotel}/>
            <Info hotel={hotel}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage;