import React from 'react'
import VideoModal from '../ModalVideo'
import SectionTitle from "../SectionTitle";


const VideoSection = (props) => {
    return(
        <div>
            <SectionTitle MainTitle={'Gdzie & Kiedy'}></SectionTitle>
            <section className="wpo-video-section">
                <h2 className="hidden">some</h2>
                <VideoModal/>
            </section>
        </div>

    )
}

export default VideoSection;