import React from 'react'
import { Link } from 'react-scroll'
import MobileMenu from '../MobileMenu/MobileMenu'


const Header = (props) => {

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  }

  return (
    <header id="header">
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">

              <div className="col-lg-2 col-md-6 col-6">

              </div>
              <div className="col-lg-8 col-md-1 col-1">
                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                  <button className="menu-close"><i className="ti-close"></i></button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li><Link activeClass="active" to="home" spy={true} smooth={true} duration={500} >Zegar</Link></li>
                    <li><Link activeClass="active" to="story" spy={true} smooth={true} duration={500} >Historia</Link></li>
                    <li><Link activeClass="active" to="event" spy={true} smooth={true} duration={500} >Wydarzenia</Link></li>
                    <li><Link activeClass="active" to="info" spy={true} smooth={true} duration={500} >Informacje</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header;