import React from 'react';
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/1.jpg'
import sImg2 from '../../images/story/2.jpg'
import sImg3 from '../../images/story/3.jpg'

import shape1 from '../../images/rsvp/shape1.png'
import shape2 from '../../images/rsvp/shape2.png'

const StorySection = (props) => {


    return (
        <section className="story-section section-padding" id="story">
            <div className="container">
                <div className="row">
                    <SectionTitle MainTitle={'Nasza historia'} />
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-balloon"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="story-timeline-item s1">
                                <div className="row align-items-center">
                                    <div className="col col-lg-6 col-12">
                                        <div className="img-holder right-align-text fadeInLeftSlow" data-wow-duration="1500ms">
                                            <img src={sImg1} className="img img-responsive" alt="stroy"/>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12">
                                        <div className="story-text left-align-text fadeInRightSlow" data-wow-duration="2000ms">
                                            <h3>Nasze pierwsze spotkanie</h3>
                                            <p>Poznaliśmy się przypadkiem.
                                                Połączyła nas pasja do sportu. Pamiętam, kiedy ćwiczyłam na siłowni i zobaczyłam wysokiego blondyna, który opierając się o sztangę szczerzył się w moją stronę. Widząc jego szczery uśmiech… uciekłam. Nie pytajcie czemu, kobiety takie już są.
                                                Dni mijały, a ja za każdym razem spotykałam mojego adoratora na siłowni. W końcu zagadał! Zapytał o imię i co ciekawego u mnie. Ja, jako człowiek, którego nie trzeba długo namawiać do wylewnych opowieści opowiedziałam mu, że chwilowo mieszkam u koleżanki, ponieważ w mieszkaniu, które wynajmowałam było włamanie. Z zaangażowaniem opowiadałam mu o policji, co złodziej mi ukradł.. kiedy nagle w połowie mojego żarliwego opowiadania przepełnionego smutkiem  Adrian krzyknął „moi znajomi wychodzą z siłowni. Muszę iść! Pa!” I wyszedł.. pomyślałam sobie „aha, to po co się szczerzysz do mnie od kilku dni.”
                                                Los chciał, że za jakiś czas znowu spotkaliśmy się w tym samym miejscu. Adrian odkupując swoje winy poprosił mnie o kontakt do siebie. Napisał do mnie kilka dni później.. zaprosił mnie na kolacje, którą sam przygotował. Jak się okazało mieszkał 300 metrów od mieszkania, które w tamtym czasie wynajmowałam. Od tej pamiętnej kolacji jesteśmy nierozłączni do dziś!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="story-timeline-item">
                                <div className="row align-items-center">
                                    <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder left-text">
                                        <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">
                                            <h3>Zaręczyny</h3>
                                            <p>Wiecie jak to jest z zaręczynami.. zrobić tak, aby kobieta niespodziewała się spodziewanego. Z Anią taka misja jest prawie niewykonalna! Zaplanowaliśmy wakacje na Corfu. Przeglądając tamtejsze przyrodnicze atrakcje napotkałem Kanał Miłości. Wiedziałem, że to idealne miejsce. Teraz tylko kupić pierścionek.. wydaje się proste, prawda? Znowu.. nie z Anią!
                                                Okazało się, że pierścionek, który uznałem za najpiękniejszy znajduje się w Bochni. Pojechałem po niego po pracy. Ani nie było w domu i według naszej wcześniejszej rozmowy wydedukowałem, że nie powinna wrócić przede mną..  plan idealny! Stojąc w korku w Bochni Ania napisała mi SMS „już wracam do domu” 2 godziny wcześniej!! Co to był za rajd, aby zdążyć do mieszkania jako pierwszy!
                                                W dniu wylotu na Corfu podczas przeglądu bagażu na lotnisku Ania zgubiła swój srebrny pierścionek! Nie dałem po sobie poznać, że nazajutrz dostanie inny! Co za zbieg okoliczności!
                                                Gdy na drugi dzień doszliśmy do Kanału Miłości, akurat był zachód słońca. Pomyślałem ... teraz albo nigdy!
                                                Natomiast Ania pomyślała… „idę się kąpać do morza”. Musiałem ją zatrzymywać, aby nie zaczęła się pluskać w słonej wodzie. Jakoś się udało. W końcu klęknąłem przed moją wybranką i zapytałem WYJDZIESZ ZA MNIE?? Na co Ania odpowiedziała  „nie…..nie wierzę”. Jej dokończenie zdania po ,,nie…” trwało wieczność. Krople potu spłynęły mi po skroni, gdy dotarło do mnie, że jednak chciała powiedzieć „nie wierzę”, a nie „nie”.
                                                Ten moment czciliśmy codziennie karafkami greckiego wina, aż do końca wakacji.</p>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                        <div className="img-holder left-align-text">
                                            <img src={sImg2} alt="stroy" className="img img-responsive fadeInRightSlow" data-wow-duration="1500ms"/>
                                                <span className="heart">
                                                    <i className="fi flaticon-dance"></i>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="story-timeline-item">
                                <div className="row align-items-center">
                                    <div className="col col-lg-6 col-12">
                                        <div className="img-holder right-align-text left-site right-heart">
                                            <img src={sImg3} alt="stroy" className="img img-responsive fadeInLeftSlow" data-wow-duration="1500ms"/>
                                                <span className="heart">
                                                    <i className="fi flaticon-dove"></i>
                                                </span>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12">
                                        <div className="story-text left-align-text fadeInRightSlow" data-wow-duration="2000ms">
                                            <h3>Ślub cywilny</h3>
                                            <p>Jak większość z Was wie, jesteśmy po ślubie cywilnym.
                                                Nasze marzenia od wielu lat snuły się wokół ślubu na plaży. Przypieczętowały to zaręczyny w Grecji. Od tego momentu wiedzieliśmy, że nasz ślub również musi być w tym kraju. Tak też się stało. 17 maja 2023 wzięliśmy ślub na wyspie Rodos. Ten czas był dla Nas niewyobrażalnie intymny. Byliśmy tam sami, bez żadnych znajomych lub rodziny.
                                                Ten magiczny moment do końca życia będzie gościł w naszych sercach i wzbudzał sentymentalny uśmiech na twarzy.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <div className="sticky-shape">
                    <img src={shape1} alt=""/>
                </div>
            </div>
            <div className="shape-2">
                <div className="sticky-shape">
                    <img src={shape2} alt=""/>
                </div>
            </div>
        </section>
    )
}


export default StorySection;