import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage'
const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Homepage/>} />
            <Route path='/hotel-krzyski' element={<Homepage hotel="hotel-krzyski"/>} />
            <Route path='/hotel-wesola' element={<Homepage hotel="hotel-wesola"/>} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
