

import React, {Fragment} from 'react';


import { Button, Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const LocationMap = ({ maxWidth, button, buttonClass, title, id }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="ti-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });


    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                {button}
                Zobacz lokalizację
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                  Lokalizacja - {title}
                </DialogTitle>

                    <Grid className="modalBody modal-body">
                        <Grid className="modalBody modal-body">
                            { id === 'wedding-party' && <iframe width="100%" height="568" src="https://maps.google.com/maps?width=100%25&amp;height=568&amp;hl=en&amp;q=+(Dom%20Weselny%20Szarwark)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> }
                            { id === 'wedding' && <iframe width="100%" height="568" src="https://maps.google.com/maps?width=100%25&amp;height=568&amp;hl=en&amp;q=+(Ko%C5%9Bci%C3%B3%C5%82%20NMP%20Szkaplerznej%20w%20Szynwa%C5%82dzie)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> }
                            { id === 'hotel-krzyski' && <iframe width="100%" height="568" src="https://maps.google.com/maps?width=100%25&amp;height=568&amp;hl=en&amp;q=+(Krzyski%20%7C%20Restauracja%20i%20Hotel,%20Krzyska%2052B,%2033-100%20Tarn%C3%B3w)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>}
                            { id === 'hotel-wesola' && <iframe width="100%" height="568" src="https://maps.google.com/maps?width=100%25&amp;height=568&amp;hl=en&amp;q=+(Hotel%20i%20Restauracja%20Weso%C5%82a)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>}
                        </Grid>
                    </Grid>
            </Dialog>
        </Fragment>
    );
}
export default LocationMap


