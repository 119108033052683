import React from 'react'
import LocationMap from '../Modal'
import SectionTitleS2 from '../SectionTitleS2'

const Events = [
    {
        title:'Hotel',
        id: 'hotel-krzyski',
        li1:'26 MAJA 2024 o godzinie 11:00',
        li2:'Krzyski | Restauracja i Hotel',
        li3:'Krzyska 52B, 33-100 Tarnów',
        li4:'+48 510 078 860',
        selector: "hotel-krzyski"
    },
    {
        title:'Hotel',
        id: 'hotel-wesola',
        li1:'26 MAJA 2024 o godzinie 11:00',
        li2:'Hotel i Restauracja Wesoła',
        li3:'Nowa Jastrząbka 92a, 33-151 Nowa Jastrząbka',
        li4:'+48 14 678 62 14',
        selector: "hotel-wesola"
    },
    {
        title:'Ślub',
        id: 'wedding',
        li1:'26 MAJA 2024 o godzinie 14:00',
        li2:'Kościół NMP Szkaplerznej w Szynwałdzie',
        li3:'Szynwałd 1, 33-158 Szynwałd',
    },
    {
        title:'Wesele',
        id: 'wedding-party',
        li1:'26 MAJA 2024 o godzinie 16:00',
        li2:'Dom Weselny Szarwark',
        li3:'Szarwark 36, 33-200 Szarwark',
    },
]



const EventSection = (props) => {

    const {hotel} = props;

    const events = Events.filter(e => !e.selector || e.selector === hotel);
    const itemsClass = !!hotel ? "col col-lg-4 col-md-6 col-12" : "col col-lg-6 col-mg-6 col-12"

    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="event">
            <div className="container">
                <div className="wpo-event-wrap">
                    <div className="row">
                        {events.map((event, eitem) => (
                            <div className={itemsClass} key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.li3}</li>
                                            <li className="wpo-event-empty">{event.li4}</li>
                                            <li ><LocationMap title={event.title} id={event.id}/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;