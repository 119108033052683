import React from 'react'
import SectionTitle from "../SectionTitle";
import {Link} from "react-router-dom";

const Info = ({hotel}) => {

    const SharedInfo =
        <>
            <p><strong>Inne:</strong></p>
            <p>Dla osób posiadających dzieci, na sali weselnej <strong> od 17:45 do 20:45 </strong> będzie obecna animatorka, która chętnie zajmie czas waszych dzieci.</p>
            <p>W razie potrzeby, w dniu ślubu prosimy kontaktować się z naszymi świadkami</p>
            <p>Jakub: <strong>791 504 388</strong> - Wiktoria: <strong>533 866 107</strong></p>
        </>

    const NoHotelInfo =
        <>
            <p><strong>Autobusy:</strong></p>
            <p>Po ślubie zapewniony jest autobus, który zawozi gości z kościoła w Szynwałdzie do Domu Weselnego Szarwark oraz po zabawie odwozi do hotelu lub domu o preferowanej godzinie w ramach czasowych <strong>00:00 - 5:00</strong></p>
        </>

    const KrzyskiInfo = <>
            <p><strong>Hotel:</strong></p>
            <p>Do hotelu można zameldować się od godziny <strong>11:00</strong></p>
            <p>Jeżeli nie chcecie meldować się w hotelu przed ślubem nie martwcie się. Recepcja jest czynna 24h. </p>
            <p>Doba hotelowa w hotelu Krzyskim jest do godziny <strong>14:00 </strong></p>
            <p>W hotelu dla gości zapewnione jest śniadanie w godzinach <strong>od 7:00 do 10:00 </strong></p>
            <p><strong>Autobusy:</strong></p>
            <p>Zapewniamy autobus z hotelu do kościoła w Szynwałdzie. Proszę aby czekać na autobus przed hotelem o godzinie <strong>13:15</strong></p>
            <p>Po ślubie autobus zawozi gości z kościoła do Domu Weselnego Szarwark oraz po zabawie odwozi do hotelu lub domu o preferowanej godzinie w ramach czasowych <strong>00:00 - 5:00</strong></p>
        </>

    const WesolaInfo =
        <>
            <p><strong>Hotel:</strong></p>
            <p>Do hotelu można zameldować się od godziny <strong>11:00</strong></p>
            <p>Jeżeli nie chcecie meldować się w hotelu przed ślubem nie martwcie się. Recepcja jest czynna 24h. </p>
            <p>Doba hotelowa w hotelu Wesoła jest do godziny <strong> 12:00 </strong></p>
            <p>W hotelu dla gości zapewnione jest śniadanie w godzinach  <strong>od 9:00 do 10:30</strong></p>
            <p><strong>Autobusy:</strong></p>
            <p>Zapewniamy autobus z hotelu do kościoła w Szynwałdzie. Proszę aby czekać na autobus przed hotelem o godzinie <strong>12:50</strong></p>
            <p>Po ślubie autobus zawozi gości z kościoła do Domu Weselnego Szarwark oraz po zabawie odwozi do hotelu lub domu o preferowanej godzinie w ramach czasowych <strong>00:00 - 5:00</strong></p>
      </>

    return (
        <section className="static-hero-s3" id="info">
            <div className="hero-container">
                <SectionTitle MainTitle={'Informacje'}></SectionTitle>
                <div className="hero-inner">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        { hotel === 'hotel-krzyski' && KrzyskiInfo }
                                        { hotel === 'hotel-wesola' && WesolaInfo }
                                        { !hotel && NoHotelInfo }
                                        {SharedInfo}
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    )
}

export default Info;